<template>
  <v-container v-if="!broken_page && !isEditing" fluid>
    <h3 class="font-weight-medium mb-2">Third Party Groups</h3>
    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="newThirdPartyGroup"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="exportedData">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :items="thirdPartyGroups"
      body-text-direction="center"
      header-text-direction="center"
      alternating
      border-cell
      :sort-by="headers[0].value"
      class="custom-data-table"
      :headers="headers"
      :search-value="searchValue"
      :loading="loading"
    >
      <template #item-delete="item">
        <v-icon @click="deleteThirdPartyGroup(item)">mdi-delete</v-icon>
      </template>
      <template #item-edit="item">
        <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
      </template>
    </EasyDataTable>
  </v-container>
  <div v-if="!broken_page && isEditing">
    <third_party_group_edit></third_party_group_edit>
  </div>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Confirmation_Modal
    :confirmation="confirmation"
    @DoConfirmation="getConfirmation()"
    @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>

  <Success_Modal
    v-if="responseResult === 1"
    :message="message"
    :success="responseResult === 1"
    @click="responseResult = 0"
  ></Success_Modal>

  <Error_Modal
    v-if="responseResult === -1"
    :error="responseResult === -1"
    :message="message"
    @closeErrorModal="responseResult = 0"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from "@/utils/helpers.js";
import third_party_groupService from "@/services/third_party_group.service.js";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";
import third_party_group_edit from "@/components/HomeManagement/ThirdParty/ThirdPartyGroup/third-party-group-edit.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Broken_Page_V01 from "@/components/Broken_Page_V01.vue";

export default {
  components: {
    Broken_Page_V01,
    Error_Modal,
    Success_Modal,
    third_party_group_edit,
    Confirmation_Modal,
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Delete", value: "delete" },
        { text: "Edit/Details", value: "edit" },
      ],
      searchValue: "",
      thirdPartyGroups: [],
      loading: true,
      confirmation: false,
      confirmed: false,
      broken_page: false,
      isEditing: false,
      underEditThirdPartyGroup: null,
      responseResult: 0,
      message: null,
    };
  },
  methods: {
    async getThirdPartyGroups() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await third_party_groupService.getThirdPartyGroups(),
      );
      if (response.success) {
        this.loading = false;
        this.thirdPartyGroups = response.data;
      } else {
        this.broken_page = true;
      }
    },

    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },

    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },

    async deleteThirdPartyGroup(thirdPartyGroup) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
          await third_party_groupService.removeThirdPartyGroup(
            thirdPartyGroup.id,
          ),
        );
        this.message = response.message;
        if (response.success) {
          this.responseResult = 1;
          await this.getThirdPartyGroups();
        } else {
          this.responseResult = -1;
        }
      }
    },

    async newThirdPartyGroup() {
      this.$store.dispatch("setIsEditingThirdPartyGroup", false);
      this.$store.dispatch("setChosenThirdPartyGroup", null);
      this.isEditing = true;
    },
    async goToEdit(item) {
      this.$store.dispatch("setChosenThirdPartyGroup", item);
      this.$store.dispatch("setIsEditingThirdPartyGroup", true);
      this.isEditing = true;
    },
  },
  computed: {
    exportedData() {
      return this.thirdPartyGroups.map(({ id, name, description }) => ({
        id,
        name,
        description,
      }));
    },
  },
  async mounted() {
    await this.getThirdPartyGroups();
  },
};
</script>

<style scoped></style>

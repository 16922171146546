<template>
  <v-card>
    <v-form ref="new_general_ledger_form">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-autocomplete
              v-model="parentAccount"
              label="Parent Account"
              item-title="label"
              item-value="id"
              return-object
              :items="generalLedgers"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :subtitle="item.raw.code"
                  :title="item.raw.label "
                ></v-list-item>
              </template>
            </v-autocomplete>
            <div class="d-flex justify-space-between">
              <v-text-field
                style="flex-basis: 25%"
                v-model="code"
                :rules="isTwoDigitNumber"
                label="Suffix"
                :maxlength="2"
              ></v-text-field>
              <v-text-field
                class="ml-10"
                style="flex-basis: 50%"
                v-model="newLedgerCode"
                variant="outlined"
                readonly
                label="New Ledger Code"
              >
              </v-text-field>
            </div>
            <v-text-field
              v-model="label"
              label="Label"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="flat" color="button_color" @click="addGeneralLedger">
        Add General Ledger
      </v-btn>
    </v-card-actions>
  </v-card>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import general_ledgerService from "@/services/general_ledger.service";
import { unifiedResponseHandler } from "@/utils/helpers";
import {
  requiredRule,
  positiveIntegerRule,
  isTwoDigitNumber,
} from "@/utils/rules.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import emitter from "@/plugins/mitt.js";

export default {
  name: "new_general_ledger_modal",
  components: { Success_Modal, Error_Modal },

  data() {
    return {
      success: false,
      error: false,
      message: null,
      requiredRule: requiredRule,
      code: null,
      label: null,
      parentAccount: null,
      accountGroup: null,
      positiveIntegerRule: positiveIntegerRule,
      generalLedgers: [],
      isTwoDigitNumber: isTwoDigitNumber,
      newCodeError: null,
    };
  },
  methods: {
    async addGeneralLedger() {
      const { valid } = await this.$refs.new_general_ledger_form.validate();
      if (valid) {
        let form = {
          code: this.newLedgerCode,
          label: this.label,
          parentLedgerId: this.parentAccount.id,
          accountGroup: this.parentAccount?.accountGroup,
        };
        console.log(form);
        let response = unifiedResponseHandler(
          await general_ledgerService.addGeneralLedger(form),
        );
        this.message = response.message;
        if (response.success) {
          emitter.emit("new_general_ledger", response);
        } else {
          this.error = true;
        }
      }
    },
    async getGeneralLedgers() {
      const response = unifiedResponseHandler(
        await general_ledgerService.getGeneralLedgers(),
      );
      if (response.success) {
        this.generalLedgers = response.data.sort((a, b) =>
          a.code.localeCompare(b.code),
        );
        console.log(this.generalLedgers);
      } else {
        this.broken_page = true;
      }
    },
  },
  async mounted() {
    await this.getGeneralLedgers();
  },
  beforeUnmount() {},
  watch: {
  },
  computed: {
    newLedgerCode() {
      return this.parentAccount && this.code
        ? this.parentAccount.code + this.code
        : null;
    },

  },
};
</script>

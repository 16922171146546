import axios from "axios";
import {authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class exchangeRateService {
    async callXE(from, to) {
        try {
            return await axios.get(BASE_URL + "exchangeRate/xe", {
                headers: authHeader(),
                params: {from: from, to: to},
            });
        } catch (error) {
            return {status: error.response.status, data: error.response.data};
        }
    }

    async getAllExchangeRate() {
        try {
            return await axios.get(BASE_URL + "exchangeRate/getAll", {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: error.response.status, data: error.response.data};
        }
    }
}

export default new exchangeRateService();

<script>


import List_of_accounts_modal_v01 from "@/components/Modals/List_Of_Accounts_Modal_V01.vue";
import {MoneyOption} from "@/utils/maska_options.js";
import General_Ledger_List_Modal_V01 from "@/components/Modals/General_Ledger_List_Modal_V01.vue";
import emitter from "@/plugins/mitt.js";
import {extractNumber, handleKeyDown, unifiedResponseHandler} from "@/utils/helpers.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import journalEntryService from "@/services/journal-entry.service.js";
import {useTheme} from "vuetify";

export default {
  name: "journal-entry.vue",
  components: {Success_Modal, Error_Modal, General_Ledger_List_Modal_V01, List_of_accounts_modal_v01},
  data() {
    return {
      headers: [
        {title: 'Account/ledger', align: 'left', key: 'accountOrLedgerName', width: '20%'},
        {title: 'Debit', align: 'end', key: 'debit', width: '40%'},
        {title: 'Credit', align: 'end', key: 'credit', width: '40%'},
      ],
      items: [],
      thirdPartyAccount: null,
      accountListModal: false,
      thirdPartyAccountRecordAdded: false,
      creditAmountAccountRow: "",
      debitAmountAccountRow: "",
      note: "",
      generalLedger: null,
      creditAmountGeneralLedgerRow: "",
      debitAmountGeneralLedgerRow: "",
      createDate : null,
      generalLedgerListModal: false,
      error: false,
      success: false,
      message: null,
    }
  },
  computed: {
    allowAdding() {
      if (this.thirdPartyAccountRecordAdded)
        return (this.creditAmountGeneralLedgerRow !== "" || this.debitAmountGeneralLedgerRow !== "") && this.generalLedger !== null
      else
        return ((this.creditAmountAccountRow !== "" || this.debitAmountAccountRow !== "") && this.thirdPartyAccount !== null)
            || ((this.creditAmountGeneralLedgerRow !== "" || this.debitAmountGeneralLedgerRow !== "") && this.generalLedger !== null)
    },
    moneyOption() {
      return MoneyOption(null);
    },
    creditAmountGeneralLedgerRowDisabled() {
      return this.debitAmountGeneralLedgerRow !== "";
    },
    debitAmountGeneralLedgerRowDisabled() {
      return this.creditAmountGeneralLedgerRow !== '';
    },
    creditAmountAccountRowDisabled() {
      return this.debitAmountAccountRow !== '';
    },
    debitAmountAccountRowDisabled() {
      return this.creditAmountAccountRow !== '';
    },
    accountCofferName() {
      return this.thirdPartyAccount ? this.thirdPartyAccount.cofferName : ""
    },
    generalLedgerLabel() {
      return this.generalLedger ? this.generalLedger.label : ""
    },
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
  },
  async mounted() {
    emitter.on("new_general_ledger", (value) => {
      this.generalLedger = value;
      this.generalLedgerListModal = false;
    });
    emitter.on("chosenAccount", (value) => {
      this.thirdPartyAccount = value;
      this.accountListModal = false;
    });
  },
  methods: {
    handleKeyDown,
    addRecord() {
      if (!this.thirdPartyAccountRecordAdded && this.thirdPartyAccount) {
        const newRecordOnAccount = {
          id: this.thirdPartyAccount.id,
          accountOrLedgerName: this.thirdPartyAccount.cofferName,
          credit: this.creditAmountAccountRow,
          debit: this.debitAmountAccountRow,
        }
        this.items.push(newRecordOnAccount);
        this.thirdPartyAccountRecordAdded = true;
      }
      const newRecordOnGeneralLedger = {
        accountOrLedgerName: this.generalLedger.label,
        credit: this.creditAmountGeneralLedgerRow,
        debit: this.debitAmountGeneralLedgerRow,
        generalLedgerCode: this.generalLedger.code
      }
      this.items.push(newRecordOnGeneralLedger);
      this.resetGeneralLedgerRow();
    },
    resetGeneralLedgerRow() {
      this.generalLedger = null;
      this.creditAmountGeneralLedgerRow = "";
      this.debitAmountGeneralLedgerRow = "";
    },
    resetAccountRow() {
      this.note = "";
      this.thirdPartyAccount = null;
      this.creditAmountAccountRow = "";
      this.debitAmountAccountRow = "";
      this.thirdPartyAccountRecordAdded = false;
    },
    async submitForm() {
      if (!this.checkIfRecordsAreValid()) {
        this.error = true;
        this.message = "Sum of credits and debits must be 0";
        return;
      }
      let ledgerEntries;
      if (this.thirdPartyAccountRecordAdded) {
        // eslint-disable-next-line no-unused-vars
        ledgerEntries = this.items.slice(1).map(({accountOrLedgerName, ...rest}) => ({
          ...rest,
          credit: extractNumber(rest.credit),
          debit: extractNumber(rest.debit)
        }));
      } else {
        // eslint-disable-next-line no-unused-vars
        ledgerEntries = this.items.map(({accountOrLedgerName, ...rest}) => ({
          ...rest,
          credit: extractNumber(rest.credit),
          debit: extractNumber(rest.debit)
        }));
      }
      let form = {
        thirdPartyAccountId: this.thirdPartyAccount?.id || null,
        accountCreditAmount: extractNumber(this.creditAmountAccountRow),
        accountDebitAmount: extractNumber(this.debitAmountAccountRow),
        createDate : this.createDate,
        note: this.note,
        ledgerEntries: ledgerEntries
      };
      let response = unifiedResponseHandler(await journalEntryService.addRecord(form));
      if (response.success) {
        console.log(response)
        this.success = true;
        this.message = response.data;
      } else {
        this.error = true;
        this.message = response.message;
      }
      this.items = [];
      this.resetGeneralLedgerRow();
      this.resetAccountRow();
    },
    checkIfRecordsAreValid() {
      let sum = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].credit !== "") {
          sum += parseFloat(extractNumber(this.items[i].credit));
        }
        if (this.items[i].debit !== "") {
          sum -= parseFloat(extractNumber(this.items[i].debit));
        }
      }
      return sum === 0
    }
  }

}
</script>

<template>
  <v-container fluid>
    <h3 class="mb-2 font-weight-medium">Journal Entry</h3>
    <v-row>
      <v-col cols="3">
        <v-text-field label="Third Party Account" v-model="accountCofferName"
                      :disabled="thirdPartyAccountRecordAdded">
          <template v-slot:append-inner>
            <v-tab
                @click.stop.prevent="accountListModal=true"
                prepend-icon="mdi-magnify"
                color="button_color"
                @mousedown.stop.prevent
            >
              select
            </v-tab>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Debit" v-maska:[moneyOption] v-model="debitAmountAccountRow"
                      :disabled="debitAmountAccountRowDisabled || thirdPartyAccountRecordAdded">
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Credit" v-maska:[moneyOption] v-model="creditAmountAccountRow"
                      :disabled="creditAmountAccountRowDisabled || thirdPartyAccountRecordAdded">
        </v-text-field>
      </v-col>
      <v-col cols="5" class="pt-2">
        <label for="date-picker">Create Date</label>

        <VueDatePicker
            auto-apply="true"
            id="date-picker"
            v-model="createDate"
            :show-timepicker="false"
        ></VueDatePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field label="General Ledger" v-model="generalLedgerLabel">
          <template v-slot:append-inner>
            <v-tab
                @click.stop.prevent="generalLedgerListModal=true"
                prepend-icon="mdi-magnify"
                color="button_color"
                @mousedown.stop.prevent
            >
              select
            </v-tab>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Debit" v-maska:[moneyOption] v-model="debitAmountGeneralLedgerRow"
                      :disabled="debitAmountGeneralLedgerRowDisabled"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Credit" v-maska:[moneyOption] v-model="creditAmountGeneralLedgerRow"
                      :disabled="creditAmountGeneralLedgerRowDisabled"></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field label="Note" v-model="note" :disabled="thirdPartyAccountRecordAdded"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="auto">
        <v-btn :color="primaryColor1" class="mr-3" :disabled="!allowAdding" @click="addRecord" >Add</v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-data-table
          :headers="headers"
          :items="items"
          class="mt-4 header-normal w-75"
          hide-default-footer
          no-data-text="No record entered yet."
      >
      </v-data-table>
    </div>
    <v-row v-if="items.length > 0" class="justify-end">
      <v-col cols="auto">
        <v-btn :color="primaryColor1" @click="submitForm" class="mr-3 mt-3 ">Submit</v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="accountListModal" width="auto">
    <List_of_accounts_modal_v01></List_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="generalLedgerListModal" width="auto">
    <General_Ledger_List_Modal_V01
        :currency-code="this.thirdPartyAccount?.currency.currencyCode || null"
        :currency-having-ledgers="true">
    </General_Ledger_List_Modal_V01>
  </v-dialog>
  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>
</template>


<style scoped>
</style>
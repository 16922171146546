import axios from "axios";
import {authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";


class journalEntryService {

    async addRecord(recordSetDao) {
        try {
            return await axios.post(
                BASE_URL + "journalEntry",
                recordSetDao,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: error.response.status, data: error.response.data};
        }
    }

    async getAll(page, size) {
        try {
            return await axios.get(BASE_URL + "journalEntry", {
                params: {
                    page: page,
                    size: size
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: error.response.status, data: error.response.data};
        }
    }
}


export default new journalEntryService()
function getMoneyTransferRequestType(requestType) {
  let transactionTypeDisplay = "";

  switch (requestType) {
    case "CASH_RECEIVE":
      transactionTypeDisplay = "Cash Receive";
      break;
    case "CASH_SEND":
      transactionTypeDisplay = "Cash Send";
      break;
    case "BANK_RECEIVE":
      transactionTypeDisplay = "Bank Receive";
      break;
    case "BANK_SEND":
      transactionTypeDisplay = "Bank Send";
      break;
    default:
      transactionTypeDisplay = requestType;
  }
  return transactionTypeDisplay;
}
export  {getMoneyTransferRequestType};


import store from "@/store";

export const setPage = async (page) => {
  let modified_page = page.replace(/\s+/g, "_");

  console.log(modified_page);
  store.dispatch("setPage", modified_page);
};

export const changeSection = (page, options) => {
  setPage(page);
  store.dispatch("setSideOptions", options);
};
export const unifiedResponseHandler = (response) => {
  let handled = { success: false, data: null, message: null };

  if (response.status === 200) {
    if (response.data?.code !== undefined) {
      if (response.data.code === 200) {
        handled.success = true;
        handled.data = response.data.data;
        handled.message = response.data.message;
      } else {
        handled.message = response.data.message;
      }
    } else {
      handled.success = true;
      handled.data = response.data;
    }
  } else {
    handled.message = handleErrorResponse(response);
  }
  return handled;
};

function handleErrorResponse(errorResponse) {
  let errorMessage = "An unexpected error occurred.";

  if (errorResponse && errorResponse.status) {
    switch (errorResponse.status) {
      case 400:
        if (errorResponse.data) {
          if (
            typeof errorResponse.data === "object" &&
            !Array.isArray(errorResponse.data)
          ) {
            errorMessage =
              Object.values(errorResponse.data).join(", ") || errorMessage;
          } else {
            errorMessage = errorResponse.data || "Bad request";
          }
        }
        break;

      case 401:
        errorMessage = "Unauthorized access. Please log in.";
        break;

      case 403:
        errorMessage =
          "Access denied. You do not have permission to perform this action.";
        break;

      case 404:
        errorMessage = "The requested resource was not found.";
        break;

      case 500:
        if (errorResponse.data && errorResponse.data.errorMessage) {
          errorMessage =
            errorResponse.data.errorMessage ||
            "An internal server error occurred.";
        } else {
          errorMessage = "An internal server error occurred.";
        }
        break;

      default:
        errorMessage = errorResponse.data || errorMessage;
        break;
    }
  }

  return errorMessage;
}

export const refreshEditUser = () => {
  store.dispatch("setIsEditingUser", false);
  store.dispatch("setChosenUser", null);
};

export const refreshEditIndividual = () => {
  store.dispatch("setIsEditingIndividual", false);
  store.dispatch("setChosenIndividual", null);
};

export const refreshEditAccountType = () => {
  store.dispatch("setIsEditingAccountType", false);
  store.dispatch("setChosenAccountType", null);
};

export const refreshEditTransactionType = () => {
  store.dispatch("setIsEditingTransactionType", false);
  store.dispatch("setChosenTransactionType", null);
};

export const convertByteCode = async (file) => {
  try {
    const byteCharacters = atob(file.file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: file.type });

    // Create a temporary URL for the file
    const url = URL.createObjectURL(blob);

    // Create a virtual link element to trigger the file download
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.click();
    // Clean up the temporary URL and link
    URL.revokeObjectURL(url);
    link.remove();
    return true;
  } catch (error) {
    return false;
  }
};
export const buildThirdPartyObject = (type) => {
  let typesDict = {
    CUSTOMER: 0,
    TRUSTEE: 1,
    SECONDARY_THIRDPARTY: 2,
    SAFE_BOX: 3,
  };
  return { name: type, id: typesDict[type] };
};

export const handleKeyDown = (event) => {
  const allowedKeys = ["Backspace"];
  const keyPressed = event.key.toUpperCase();

  if (!allowedKeys.includes(keyPressed)) {
    event.preventDefault(); // Prevent the default behavior of the key
    event.stopPropagation(); // Stop the event from bubbling up the DOM
  }
};
export const extractNumber = (num) => {
  try {
    if (num.startsWith("0.")) {
      return "." + num.substring(2).replace(/[^0-9.]+/g, "");
    }
    return num.replace(/[^0-9.]+/g, "");
  } catch {
    return null;
  }
};

export const formatCurrencyInfo = (currencies) => {
  return currencies.map((currency) => ({
    id: currency.id,
    name: currency.symbol + " - " + currency.name,
    symbol: currency.symbol,
  }));
};

export const getIndex = (
  tabs,
  status,
  changeTransactionRequestStatusDisplay,
) => {
  console.log("tabs", tabs, "status", status);
  try {
    if (status !== "NOT INITIATED" && status !== "CANCELED") {
      let modified_status = status.replace(/_/g, " ");
      modified_status =
        modified_status === "TRANSACTION REQUESTED" &&
        changeTransactionRequestStatusDisplay
          ? "TRANSACTION REQ"
          : modified_status;
      let index = tabs.findIndex((tab) => tab.title === modified_status);
      return index + 1;
    } else {
      return "_";
    }
  } catch (error) {
    return null;
  }
};

export const findIndexWithName = (list, name) => {
  try {
    return list.findIndex((item) => item.name === name);
  } catch {
    return null;
  }
};

export const extractCodeAndPhone = (inputString) => {
  const regex = /^(\+\d{1,4})-(\d{1,15})$/;
  const match = inputString.match(regex);

  if (match) {
    const countryCodeWithPlus = match[1];
    const countryCode = countryCodeWithPlus.substring(1); // Remove the plus symbol
    const phoneNumber = match[2];

    return { countryCode, phoneNumber };
  } else {
    return { countryCode: null, phoneNumber: null }; // Return null if the input string doesn't match the expected format.
  }
};

export const findCountryByCode = (countryList, countryCode) => {
  for (let i = 0; i < countryList.length; i++) {
    console.log("in finding index");
    console.log(countryList[i]);
    console.log(countryCode);
    if (countryList[i].internationalCode === countryCode) {
      return countryList[i];
    }
  }
  return null;
};

export const combineCodeAndNumber = (country, number) => {
  if (number != null && country != null && country !== "" && number !== "") {
    return "+" + country.internationalCode + "-" + number;
  } else {
    return null;
  }
};

export const pageResponseHandler = (response) => {
  let handled = {
    success: false,
    data: null,
    message: null,
    totalItems: 0,
    numberOfPages: null,
  };

  if (response.status === 200) {
    handled.success = true;
    handled.data = response.data.data;
    (handled.totalItems = response.data.totalElements),
      (handled.pageSize = response.data.size);
    handled.numberOfPages = Math.floor(handled.totalItems / response.data.size);
  }
  return handled;
};

export function formatNumberInternal(number) {
  if (number === null || number === undefined) return null;
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 7, // Minimum number of decimal places (4 for 0.00000001)
  });
  return formatter.format(number);
}

export function formatNumber(number) {
  if (!number) return "0.00";
  const isNegative = number < 0;
  const absoluteNumber = Math.abs(number);
  const formattedNumber = absoluteNumber.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return isNegative ? `(${formattedNumber})` : formattedNumber;
}

import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";

class reminderService {
  async addReminder(reminder) {
    try {
      return await axios.post(BASE_URL + "reminder", reminder, {
        headers: authHeader(),
      });
    } catch (error) {
      console.log(error);
      return {status: error.response.status, data: error.response.data};
    }
  }

  async editReminder(reminder) {
    try {
      return await axios.post(BASE_URL + "reminder/edit", reminder, {
        headers: authHeader(),
      });
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }

  async deleteReminder(reminderId) {
    try {
      return await axios.delete(BASE_URL + "reminder?id=" + reminderId, {
        headers: authHeader(),
      });
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }

  async getAllReminders() {
    try {
      return await axios.get(BASE_URL + "reminder/getAll", {
        headers: authHeader(),
      });
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }

  async updateReminderStatus(reminderId, newStatus) {
    try {
      return await axios.post(BASE_URL + "reminder/status?id=" + reminderId + "&status=" + newStatus, {} , {
        headers: authHeader(),
      });
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }
}

export default new reminderService();

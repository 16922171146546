<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">General Ledgers</h3>
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field placeholder="Search" v-model="searchValue">
              <template v-slot:prepend-inner>
                <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="newGeneralLedgerModal = true"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn
            color="button_color"
            @click="QGeneralLedgerModal = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="getGeneralLedgersDetails">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
        :loading="loading"
        :headers="headers"
        :items="getGeneralLedgersDetails"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        body-text-direction="left"
        header-text-direction="center"
        class="custom-data-table"
      >
        <template #item-parentAccount="item">
          <span :class="{ 'null-value': item.parentAccount === 'NULL' }">
            {{ item.parentAccount }}
          </span>
        </template>
        <template #item-balance="item">
          <span
            class="text-right d-flex justify-end"
            :class="{ 'null-value': item.balance === 'NULL' }"
          >
            {{ item.balance }}
          </span>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="newGeneralLedgerModal" max-width="500">
      <new_general_ledger_modal></new_general_ledger_modal>
    </v-dialog>
    <v-dialog v-model="QGeneralLedgerModal" width="auto">
      <Q_GeneralLedgers_Modal_V01></Q_GeneralLedgers_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Q_GeneralLedgers_Modal_V01 from "@/components/ModalQ/Q_GeneralLedgers_Modal_V01.vue";
import { formatNumber, unifiedResponseHandler } from "@/utils/helpers";
import general_ledgerService from "@/services/general_ledger.service";
import new_general_ledger_modal from "../Modals/new-general-ledger-modal.vue";
import emitter from "@/plugins/mitt";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import getAccountGroupDisplay from "@/utils/NewFunctions/getAccountGroupDisplay.js";

export default {
  name: "list_of_general_ledgers_v01",
  components: {
    Error_Modal,
    Success_Modal,
    new_general_ledger_modal,
    Broken_Page_V01,
    Q_GeneralLedgers_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: "Code", value: "code" },
        { text: "Label", value: "label" },
        { text: "Parent Account", value: "parentAccount" },
        { text: "Account Group", value: "accountGroup" },
        { text: "Balance", value: "balance" },
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      generalLedgers: [],
      newGeneralLedgerModal: false,
      QGeneralLedgerModal: false,
      loading: false,
    };
  },

  methods: {
    async getGeneralLedgers() {
      let response;
      this.loading = true;
      response = unifiedResponseHandler(
        await general_ledgerService.getGeneralLedgers(),
      );
      if (response.success) {
        this.loading = false;
        this.generalLedgers = response.data.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        });
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getGeneralLedgers();
    emitter.on("new_general_ledger", (response) => {
      this.getGeneralLedgers();
      this.newGeneralLedgerModal = false;
      this.message = response.message;
      this.success = true;
      console.log(response.data);
    });
  },
  computed: {
    getGeneralLedgersDetails() {
      return this.generalLedgers.map((generalLedger) => ({
        code: generalLedger.code,
        label: generalLedger.label,
        parentAccount: generalLedger.parentLedger?.label || "NULL",
        accountGroup: getAccountGroupDisplay(generalLedger.accountGroup),
        balance: generalLedger.balance
          ? formatNumber(generalLedger.balance)
          : "NULL",
      }));
    },
  },
};
</script>

<style scoped>

</style>

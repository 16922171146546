import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";
class GeneralLedgerService {
  async getGeneralLedgers() {
    try {
      return await axios.get(BASE_URL + "generalLedger/getAll", {
        headers: authHeader(),
      });
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }

  async addGeneralLedger(add_general_ledger_form) {
    try {
      return await axios.post(
        BASE_URL + "generalLedger/add",
        add_general_ledger_form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return {status: error.response.status, data: error.response.data};
    }
  }
}

export default new GeneralLedgerService();
